'use client';

import DoctorsTable from '@/app/components/doctors/table';
import { useDebounce } from '@/hooks/useDebounce';
import { type StatusLabel, statusName } from '@/utils/constants';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  Center,
  Flex,
  Input,
  Spinner,
  Tab,
  TabList,
  Tabs,
} from '@chakra-ui/react';
import { useToasts } from '@medsimples/design-system';
import {
  LegalEntityType,
  ProfessionalStatus,
} from '@medsimples/doctor-onboarding-openapi-v2';
import { useSearchParams } from 'next/navigation';
import { Suspense, useState } from 'react';
import ExportReportModal from '../../components/modals/exportReportModal';
import {
  closeExportModalComponent,
  findStatusIndexComponent,
  getAdmin,
  getStatusesCountComponent,
} from '../../components/professionals/professionals_list';
import { useAuth } from '../../providers/auth_provider_client';
import { useDesignTokens } from '../../providers/design_tokens_provider';

function DoctorsPage() {
  const tokens = useDesignTokens();
  const searchParams = useSearchParams();
  const auth = useAuth();
  const ffs = auth.featureFlags;
  const legalEntityType = LegalEntityType.COMPANY;

  const [currentStatus, setCurrentStatus] = useState<StatusLabel[]>([]);
  const [textSearch, setTextSearch] = useState(
    searchParams.get('search') ?? '',
  );
  const textSearchDebounced = useDebounce(textSearch, 700);
  const [isLoading, setIsLoading] = useState(false);
  const [isExportReportModalOpen, setIsExportReportModalOpen] = useState(false);
  const { errorToast } = useToasts();

  const { data: statusCount } = getStatusesCountComponent(
    auth,
    legalEntityType,
  );

  const { data: admin, isFetching: isAdminFetching } = getAdmin(auth);

  const closeExportModal = async (
    emitReport?: boolean,
    withFilters?: boolean,
  ) => {
    closeExportModalComponent(
      setIsLoading,
      searchParams,
      errorToast,
      setIsExportReportModalOpen,
      emitReport,
      withFilters,
      legalEntityType,
    );
  };

  const findStatusIndex = (value: StatusLabel) => {
    return findStatusIndexComponent(value, searchParams);
  };

  const isAnyLoading = isLoading || isAdminFetching;

  return auth?.user?.isAuthenticated ? (
    <Card width={'100%'} paddingY={6} paddingX={6}>
      <Flex justifyContent='flex-start' alignItems='center' marginBottom={4}>
        <Tabs
          width='100%'
          variant='soft-rounded'
          colorScheme={tokens.button.primary.scheme}
          index={Object.keys(statusName).findIndex(findStatusIndex)}
          onChange={(index) =>
            setCurrentStatus(
              Object.keys(statusName)[index] === 'ALL'
                ? []
                : ([Object.keys(statusName)[index]] as StatusLabel[]),
            )
          }
        >
          <TabList width='100%' justifyContent='center'>
            {Object.entries(statusName)
              .filter(
                ([status]) =>
                  // ignore pre-approval status if feature is disabled
                  ffs.ENABLE_PRE_APPROVAL ||
                  ![
                    ProfessionalStatus.WAITING_PRE_APPROVAL,
                    ProfessionalStatus.PRE_APPROVED,
                  ].includes(status as ProfessionalStatus),
              )
              .map(([status, statusLabel]) => (
                <Tab
                  key={status}
                  fontWeight={
                    findStatusIndex(status as StatusLabel) ? 500 : 300
                  }
                  fontSize={14}
                  isDisabled={isAnyLoading}
                >
                  {`${statusLabel} (${statusCount?.[status] ?? 0})`}
                </Tab>
              ))}
          </TabList>
        </Tabs>
      </Flex>

      <Flex
        justifyContent='flex-start'
        alignItems='center'
        flexWrap='nowrap'
        borderRadius={'0.5rem'}
        paddingBottom={6}
      >
        <Input
          placeholder={`Digite um CPF, CNPJ, Nº Conselho, ${
            ffs.AMIL_SAP_BP_INTEGRATION ? 'BP' : ''
          } ou nome para pesquisar`}
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
          isDisabled={isAnyLoading}
          variant='outline'
          background='white'
          marginRight={4}
        />
        <Button
          variant={'outline'}
          colorScheme={tokens.button.primary.scheme}
          fontWeight={400}
          backgroundColor={tokens.text.white.color}
          size={'md'}
          isDisabled={isAnyLoading}
          onClick={() => setIsExportReportModalOpen(true)}
          leftIcon={<ExternalLinkIcon />}
        >
          Exportar relatório
        </Button>
      </Flex>
      <DoctorsTable
        textSearch={textSearchDebounced as string}
        tabStatus={currentStatus}
        admin={admin}
        resetSearch={() => setTextSearch('')}
        legalEntityType={legalEntityType}
      />
      <ExportReportModal
        isOpen={isExportReportModalOpen}
        closeModal={closeExportModal}
        isLoading={isLoading}
      />
    </Card>
  ) : (
    <Center width={'100%'} marginTop={'30vh'} verticalAlign={'center'}>
      <Spinner size={'xl'} />
    </Center>
  );
}

export default function DoctorsPageSuspense() {
  return (
    <Suspense>
      <DoctorsPage />
    </Suspense>
  );
}
